
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    PlusOutlined
} from '@ant-design/icons-vue';
import Editor from './editor';

export default {
    name: "BasicUser",
    components: {
        DeleteOutlined,
        DownOutlined,
        PlusOutlined,
        Editor
    },
    data() {
        return {
            url: '/admin/admin/list',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            eiditTitle: this.$t('新建'),
            // 角色列表
            roles: []
        };
    },
    computed: {
        columns() {
            const add = []
            if (['is_debug', 'fhjy.lbl.morelink56.com'].includes(this.$setting.env)) {
                add.push({
                    title: this.$t('账号类型'),
                    dataIndex: 'admin_type',
                    width: 150,
                    sorter: true,
                    customRender: ({
                        text
                    }) => {
                        return ({
                            '2': '管理员',
                            '3': '业务员'
                        } [text]) || ''
                    }
                })
            }

            return [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('登录账号'),
                    dataIndex: 'account',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('角色'),
                    dataIndex: 'role_name',
                    width: 150,
                    sorter: true
                },
                ...add,
                {
                    title: this.$t('联系邮箱'),
                    dataIndex: 'contact_email',
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t('联系电话'),
                    dataIndex: 'contact_tel',
                    sorter: true,
                    width: 200
                },
                {
                    title: this.$t('联系人'),
                    dataIndex: 'contact_name',
                    sorter: true,
                    slots: {
                        customRender: 'contact'
                    }
                },
                {
                    title: this.$t('启用'),
                    dataIndex: 'enabled',
                    width: 100,
                    sorter: true,
                    align: 'center',
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t('注册时间'),
                    dataIndex: 'created',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    width: 120,
                    align: 'center',
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /**
         * 获取会员类型
         */
        loadDataRole() {
            this.$http.get("/admin/role/simple_list/admin").then(res => {
                if (res.data.code === 0) {
                    this.roles = res.data.data.map(i => {
                        return {
                            value: i.value,
                            label: i.text
                        }
                    });
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = Object.assign({}, row);
            this.eiditTitle = row ? this.$t('编辑') : this.$t('新建')
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/admin/delete/', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/admin/delete/', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 重置密码 */
        resetPsw(row) {
            this.$confirm({
                title: this.$t('提示'),
                content: this.$t("是否确定重置改用户的密码为'123456'?"),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.get(`/admin/admin/reset_password/${row.id}?password=` + '123456').then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 修改状态 */
        editState(checked, row, field) {
            this.$http.get(`/admin/admin/state/${row.id}?${field}=${(checked ? 1 : 2)}`).then(res => {
                if (res.data.code === 0) {
                    row[field] = checked;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 下拉菜单点击事件 */
        dropClick(command, record) {
            if (command === 'remove') { // 删除
                this.$confirm({
                    title: this.$t('提示'),
                    content: this.$t('确定要删除选中的数据吗?'),
                    icon: createVNode(ExclamationCircleOutlined),
                    maskClosable: true,
                    onOk: () => this.remove(record)
                });
            } else if (command === 'resetPsw') {
                this.resetPsw(record);
            }
        }
    },
    async mounted() {
        this.loadDataRole();
    }
}
